<template>
  <div class="construction-home">
    <img alt="Logotipo JVActivos" src="./assets/logo.png" class="logo">
    <p class="comming">Subasta, gestión y compraventa de activos.</p>
    <p class="main-info">Plataforma en construcción...</p>
    <!-- <div class="newsletter-container">
      <p class="newsletter-title">¿Quieres estar al tanto de oportunidades en maquinaria, activos, inmobiliaria o empresas?</p>
      <p>Apúntate a nuestra newsletter y te mantendremos informado.<br>
      No seremos pesados, ¡lo prometemos!</p>
    </div> -->
    <p class="comming">Contacta con nosotros</p>
    <a href="mailto:comercial@jvactivos.com" target="_blank" rel="noopener noreferrer" @click="notify('contact-mail')">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
      </svg>
      comercial@jvactivos.com
    </a>
    <a href="tel:+34686 923 546" target="_blank" rel="noopener noreferrer" @click="notify('contact-phone')">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
      </svg>
      686 923 546
    </a>
    <a href="https://www.instagram.com/jvactivos/" target="_blank" rel="noopener noreferrer" @click="notify('click-instagram')">
      <img src="icons/instagram.png" alt="Icono Instagram">
      @jvactivos
    </a>
  </div>
</template>

<script>

export default {
  name: 'App',
  methods: {
    notify(type) {
      let uuidMetrics = ''
      if (type === 'visit') {
        uuidMetrics = '8c3dd06a-bd33-4cce-b43d-d382867e38a4'
      }
      if (type === 'contact-mail') {
        uuidMetrics = '481e2096-816e-4ff7-8bca-af95aded6a4c'
      } 
      if (type === 'contact-phone') {
        uuidMetrics = '71d5d784-ef5c-4380-b2b9-a22e54dd4702'
      }
      if ( type === 'click-instagram' ){
        uuidMetrics = '010c12ff-e119-4b54-a84a-9f1498e3bde1'
      }

      fetch(`https://metricswave.com/webhooks/${uuidMetrics}`)
    }
  },
  mounted() {
    this.notify('visit')
  }
}
</script>

<style>
  body{
    font-family: 'Quicksand', sans-serif;
    font-weight: 300;
  }

  .construction-home{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    width: 90%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .construction-home .logo{
    max-width: 120px;
    margin-bottom: 10px;
    height: auto;
  }

  .comming {
    font-size: 24px;
    font-weight: 700;
    font-family: 'Lato', sans-serif;
    margin-bottom: 10px;
    text-align: center;
  }

  .main-info {
    font-size: 16px;
    margin: 0 auto;
    text-align: center;
  }

  .newsletter-container {
    padding: 15px;
    margin-top: 30px;
    border-radius: 5px;
    border: 1px solid rgba(129, 129, 129, 0.205);
    text-align: center;
  }

  .newsletter-title {
    font-weight: 700;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
    color: black;
    margin-bottom: 15px;
    transition: .2s ease-in-out;
  }

  a:hover{
    scale: 1.05;
  }

  a svg, a img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
  }
</style>
